import { Layout, theme } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import ProductDetail from "./components/ProductDetail";

const { Header, Content } = Layout;
const ProductPage: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <Header style={{ display: "flex", padding: 0, alignItems: "center" }}>
        <img
          width={50}
          height={50}
          style={{ marginLeft: 10 }}
          alt=""
          src="/images/Backlink.png"
        />

        <div style={{ flex: 1 }}>
          <Title
            level={5}
            style={{
              margin: 0,
              padding: 0,
              textAlign: "center",
              color: "white",
            }}
          >
            Fiyat Gör - Öznur Elektrik ve Aydınlatma
          </Title>
        </div>
      </Header>
      <Content style={{ height: "calc(100vh - 65px)" }}>
        <div
          style={{
            background: colorBgContainer,
            minHeight: "100%",
            overflow: "auto",
            maxHeight: "calc(100vh - 65px)",
            borderRadius: borderRadiusLG,
          }}
        >
          {/* <img src="https://ideacdn.net/shop/df/76/myassets/products/980/ac20-00100.jpg?revision=1697143329"/> */}
          <ProductDetail images={["https://ideacdn.net/shop/df/76/myassets/products/980/ac20-00100.jpg?revision=1697143329"]}/>
        </div>
      </Content>
    </Layout>
  );
};

export default ProductPage;
