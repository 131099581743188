import React from "react";

import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainLayout from "./pages/MainLayout";
import ProductPage from "./pages/ProductPage";
import { ConfigProvider } from "antd";

const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: ":companyId/:productId",
          element: <ProductPage />,
        },
      ],
    },
  ]);

  return (
    <ConfigProvider theme={{
      components:{
        
      }
    }}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default App;
