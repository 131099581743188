import Title from "antd/es/typography/Title";
import React from "react";
import './product_detail.css';
import SimpleImageSlider from "react-simple-image-slider";
interface ProductDetailProps {
  images: string[];
}
const ProductDetail: React.FC<ProductDetailProps> = ({ images }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems:"center",
        textAlign: "center",
        marginLeft:15,
        marginRight:15
      }}
    >
      <SimpleImageSlider
        width={340}
        height={340}
        images={images}
        navSize={40}
        showBullets={false}
        showNavs={false}
      />
      <Title style={{margin:0,fontWeight:"bold",fontSize:"large"}} level={5}>ACKAD0200130 ACK LED TAVAN ARMATÜRÜ 18W 6500K</Title>
      <Title  style={{ color: "darkred",margin:0,fontWeight:"bold",fontSize:"large" }} level={5}>
        Nakit = ₺213,00
      </Title>
      <Title style={{margin:0,fontWeight:"bold",fontSize:"large"}} level={5}>Taksit = ₺0,00</Title>
    </div>
  );
};

export default ProductDetail;
